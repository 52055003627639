import React from 'react';
import TimelineItem from '../items/TimelineItem';
import { IoBookOutline } from 'react-icons/io5';

function TimelineSection({ title, items }) {
  return (
    <section className="timeline">
      <div className="title-wrapper">
        <div className="icon-box">
          <IoBookOutline />
        </div>
        <h3 className="h3">{title}</h3>
      </div>

      <ol className="timeline-list">
        {items.map((item, index) => (
          <TimelineItem key={index} title={item.title} date={item.date} text={item.text} />
        ))}
      </ol>
    </section>
  );
}

export default TimelineSection;
