import React from 'react';
import SkillItem from '../items/SkillsItem';

function SkillsSection({ skills }) {
  return (
    <section className="skill">
      <h3 className="h3 skills-title">My skills</h3>
      <ul className="skills-list content-card">
        {skills.map((skill, index) => (
          <SkillItem key={index} name={skill.name} level={skill.level} />
        ))}
      </ul>
    </section>
  );
}

export default SkillsSection;
