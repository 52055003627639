import React from 'react';

import TimelineSection from '../components/Resume/sections/TimelineSection';
import SkillsSection from '../components/Resume/sections/SkillsSection';

function ResumePage() {
    return (
        <article className="resume" data-page="resume">
          <header>
            <h2 className="h2 article-title">Resume</h2>
          </header>
    
          <TimelineSection title="Education" items={[
            { title: "Higher Vocational Training in Web Application Development", date: "2021 — 2023", text: "I completed my training in the field of web development, focusing on the technologies of the Frontend and Backend." },
            { title: "High school of Humanities and Social Sciences", date: "2019 — 2021", text: "During this stage of my studies, I focused on the fields of technology and economics." }
          ]} />
    
          <TimelineSection title="Experience" items={[
            { title: "Ovixia - Web developer", date: "2024 — Present", text: "Currently developing DataPocket App." },
            { title: "Altia - Web developer", date: "2023 — 2024", text: "During my time at Altia, I worked on two key projects, one on freight transport logistics and another on supplier management, for a major textile company." },
          ]} />
    
          <SkillsSection skills={[
            { name: "Web design", level: 70 } ,
            { name: "Frontend", level: 70 },
            { name: "Backend", level: 85 },
            { name: "Databases", level: 80 },
          ]} />
        </article>
      );
}

export default ResumePage;