import React from 'react';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item">
          <button className="navbar-link" onClick={() => navigate('/')}>
            About
          </button>
        </li>
        <li className="navbar-item">
          <button className="navbar-link" onClick={() => navigate('/resume')}>
            Resume
          </button>
        </li>
        <li className="navbar-item">
          <button className="navbar-link" onClick={() => navigate('/portfolio')}>
            Portfolio
          </button>
        </li>
        <li className="navbar-item">
          <button className="navbar-link" onClick={() => navigate('/blog')}>
            Blog
          </button>
        </li>
        <li className="navbar-item">
          <button className="navbar-link" onClick={() => navigate('/contact')}>
            Contact
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
