import React from 'react';

function AboutText() {
  return (
    <section class="about-text">
          <p>
          My name is Iván, I’m 22 years old, and I’m a fullstack web developer.
          I work at a startup in A Coruña, contributing to all the technical aspects of the application.
          I enjoy solving complex problems and creating efficient, scalable, and user-friendly solutions.
          </p>

          <p>
          I love creating original solutions that solve real-world problems.
          I believe that good design and a high-quality user experience are essential for creating successful products.
          I strive to create designs and architectures that are easy to understand.
          </p>
        </section>
  );
}

export default AboutText;
