import React from 'react';
import ProjectItem from '../components/Portfolio/ProjectItem';


function PortfolioPage() {
          //#TODO: fix image aspect
    return (
        <article class="portfolio" data-page="portfolio">
    
            <header>
              <h2 class="h2 article-title">Portfolio</h2>
            </header>
    
            <section class="projects">
    
              <ul class="project-list">
                <ProjectItem
                  href={"https://links.ivangg.dev/"}
                  imgSrc={"/media/linksmanager.png"}
                  altText={"links manager"}
                  title={"Links Manager"}
                  category={"Personal"}
                />
                <ProjectItem
                  href={"https://github.com/ivangarciagarcia/GGBowling"}
                  imgSrc={"/media/ggbowling.jpg"}
                  altText={"ggbowling"}
                  title={"GGBowling"}
                  category={"Personal"}
                />
                <ProjectItem
                  href={"https://github.com/ivangarciagarcia/G-Notas"}
                  imgSrc={"/media/G-Notas.jpg"}
                  altText={"g-notas"}
                  title={"G-Notas"}
                  category={"Personal"}
                />
              </ul>
    
            </section>
    
          </article>
      );
}

export default PortfolioPage;