import React from 'react';
import AboutText from '../components/AboutMe/sections/AboutText';
import AboutService from '../components/AboutMe/sections/AboutService';


function AboutPage() {
  return (
      <article class="about  active" data-page="about">
        <header>
          <h2 class="h2 article-title">About me</h2>
        </header>
        <AboutText />
        <AboutService />
        {/* <AboutTestimonials />
        <AboutClients /> */}
      </article>
  )
    
}

export default AboutPage;
