import React from 'react';

function SkillItem({ name, level }) {
  return (
    <li className="skills-item">
      <div className="title-wrapper">
        <h5 className="h5">{name}</h5>
        <data value={level}>{level}%</data>
      </div>
      <div className="skill-progress-bg">
        <div className="skill-progress-fill" style={{ width: `${level}%` }}></div>
      </div>
    </li>
  );
}

export default SkillItem;